@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* width */
::-webkit-scrollbar {
  @apply w-[10px] p-2
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-white p-3
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gray-500 w-[7px] mx-auto rounded-full 
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply hover:bg-gray-500 duration-1000
}


.active div{
  @apply text-red -translate-y-7 
  
}
.active1 div{
  @apply text-red
}

/* *{
  cursor:none ;
}
.cursor{
  width: 42px;
  height: 42px;
  background-color: red;
  opacity: 0.3;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.1s linear;
  z-index: 9999999;
}
.minicursor{
  width: 7px;
  height: 7px;
  background-color: red;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0s ease-in-out;
  z-index: 999;
} */

.navbar ul li{
  @apply !cursor-pointer
}

.block_padding{
  @apply  py-[80px] md:py-[100px] lg:py-[120px]
}

.progres div{
  @apply bg-red
}



.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  font-size: 18px;
  padding: 20px 35px;
  color: white;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  }

  @media screen and(max-width:1140px) {
    .swiper-slide{
      padding: 20px 100px !important;
    }

  }

  section{
    background-color: #00c2cb;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  section:nth-child(1){
    color: #e0ffff;
    }
  section:nth-child(2){
    color: #42455a;
    background: #e0ffff;
  } 
  section:nth-child(3){
    color: #e0ffff;
  }
  section:nth-child(4){
    color: #42455a;
    background: #e0ffff;
  }
  section .container{
    margin: 100px;
  }
  section h1{
    font-size: 3rem;
    margin: 20px;
  }
  section h2{
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
  }
  section .text-container{
     display: flex;
  }
  section .text-container .text-box{
    margin: 20px;
    padding: 20px;
    background: #00c2cb;
  }
  section .text-container .text-box h3{
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  
  @media (max-width: 900px){
    section h1{
      font-size: 2rem;
      text-align: center;
    }
    section .text-container{
      flex-direction: column;
    }
  }
  
  .reveal{
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
  }
  
  .reveal.active{
    transform: translateY(0);
    opacity: 1;
  }



  .serviceblock-transition {
    transform: translateX(0px);
    opacity: 1;
  }
  .portfoliosBloks-transition {
    transform: translateX(0px);
    opacity: 1;
  }

  .newsBlogs-transition{
    transform: translateX(0px);
    opacity: 1;
  }